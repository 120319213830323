import { Button, Container, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IntroHead from '#/components/shared/IntroHead';
import MainActions from '#/components/pages/DigitalAssets/main-actions';
import useLocales from '#/hooks/useLocales';
import DigitalAssetForm from '#/components/pages/DigitalAssets/digital-asset-form';
import DigitalAssetsTable from '#/components/pages/DigitalAssets/table';
import {
  invalidateDigitalAssetsQueries,
  useExportDigitalAssets,
  useGetDigitalAssets,
} from '#/api/digitalAssets';
import useLocalStorage from '#/hooks/useLocalStorage';

export default function DigitalAssets() {
  const navigate = useNavigate();
  const { translate } = useLocales();

  const [ownerID] = useLocalStorage('ownerID', undefined);

  const [showForm, setShowForm] = useState<boolean>(false);
  const [enableDownload, setEnableDownload] = useState<boolean>(false);

  const { data: exportURL, isLoading: loadingURL } = useExportDigitalAssets(
    enableDownload,
    ownerID || undefined
  );

  const handleEnableDownload = () => setEnableDownload(true);

  useEffect(() => {
    if (exportURL) {
      window.open(exportURL.file_url, '_blank')?.focus();
      setEnableDownload(false);
      invalidateDigitalAssetsQueries.exportDigitalAssets();
    }
  }, [exportURL]);

  const { data: digitalAssets } = useGetDigitalAssets(1, ownerID || undefined);

  const isEmptyList = digitalAssets?.results?.length === 0;

  const handleShowForm = () => setShowForm(true);
  const handleHideForm = () => setShowForm(false);

  const handleBackToDashboard = () => {
    navigate('/dashboard/digitaler-nachlass');
  };

  return (
    <Container>
      <Stack direction="row" justifyContent="center">
        <Button onClick={handleBackToDashboard} sx={{ width: 'fit-content' }}>
          <Typography>{String(translate('global.back'))}</Typography>
        </Button>
      </Stack>
      <IntroHead
        title={String(translate('digitalAssets.introHead'))}
        description={String(translate('digitalAssets.introDescription'))}
      />
      {!showForm && (
        <>
          <MainActions
            isEmptyList={isEmptyList}
            showForm={handleShowForm}
            downloadList={handleEnableDownload}
            preparingURL={loadingURL}
            // downloadPasswordManagerTemplate={
            //   handleDownloadPasswordManagerTemplate
            // }
          />
          {!isEmptyList && (
            <DigitalAssetsTable handleShowForm={handleShowForm} />
          )}
        </>
      )}
      {showForm && <DigitalAssetForm hideForm={handleHideForm} />}
    </Container>
  );
}
